import axiosInstance from "@app/axiosInstance";
import { T_Company } from "@app/entities/company/types";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";

export const useCompanyMutations = () => {
  const queryClient = useQueryClient();
  const { selectedCompany, setSelectedCompanyId } = useCompany();
  const companyId = selectedCompany?.companyId;

  const { mutate: joinCompany, isPending: isJoinCompanyPending } = useMutation({
    mutationFn: (payload: { adminActivationCode: string }) =>
      axiosInstance
        .post("/admin/company/join", payload)
        .then((response) => response.data),
    onSuccess: ({ companyId }) => {
      notification.success({
        description: "Successfully joined company",
        message: "Success",
      });
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes("companies"),
      });
      setSelectedCompanyId(companyId);
    },
    onError: (error: Error) => {
      console.error(error);
      notification.error({
        description: error.message ?? "Failed to join company",
        message: "Error",
      });
    },
  });

  const {
    mutate: refreshAdminActivationCode,
    isPending: isRefreshAdminActivationCodePending,
  } = useMutation({
    mutationFn: () =>
      axiosInstance.get(`/admin/company/${companyId}/refresh-admin`),
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to refresh activation code" });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes("companies"),
      });
      notification.success({
        description: "Activation code has been refreshed",
        message: "Success",
      });
    },
  });

  const {
    mutate: refreshMemberActivationCode,
    isPending: isRefreshMemberActivationCodePending,
  } = useMutation({
    mutationFn: () =>
      axiosInstance.get(`/admin/company/${companyId}/refresh-member`),
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to refresh activation code" });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        predicate: (query) => query.queryKey.includes("companies"),
      });
      notification.success({
        description: "Activation code has been refreshed",
        message: "Success",
      });
    },
  });

  const {
    mutate: deactivateAllMembers,
    isPending: isDeactivateAllMembersPending,
  } = useMutation({
    mutationFn: () =>
      axiosInstance.delete(`/admin/company/${companyId}/deactivate`),
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to deactivate all members" });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company", companyId] });
      notification.success({
        description: "All members have been deactivated",
        message: "Success",
      });
    },
  });

  const {
    mutate: updateNotifications,
    isPending: isUpdateNotificationsPending,
  } = useMutation({
    mutationFn: ({
      lowBalanceEmail,
      staleReportsEmail,
      lowBalanceThreshold,
    }: {
      lowBalanceEmail: string;
      staleReportsEmail: string;
      lowBalanceThreshold: number;
    }) =>
      axiosInstance.put(`/admin/company/${companyId}/notifications`, {
        lowBalanceEmail,
        staleReportsEmail,
        lowBalanceThreshold,
      }),
    onError: (error) => {
      console.error(error);
      notification.error({
        message: "Failed to update notification preferences",
      });
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["company", companyId] });
      notification.success({
        description: "Notification preferences have been updated",
        message: "Success",
      });
    },
  });

  const { mutate: checkout, isPending: isCheckoutPending } = useMutation({
    mutationFn: (checkoutData: {
      cancelUrl: string;
      companyId: number;
      successUrl: string;
    }) =>
      axiosInstance
        .post("/stripe/checkout", checkoutData)
        .then((response) => response.data),
    onSuccess: async ({ sessionId }) => {
      if (!sessionId) {
        throw new Error("Failed to create session");
      }
    },
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to create checkout session" });
    },
  });

  const { mutate: registerCompany, isPending: isRegisterCompanyPending } =
    useMutation({
      mutationFn: (companyData: T_Company) =>
        axiosInstance
          .post("/admin/company", companyData)
          .then((response) => response.data),
      onError: (error) => {
        console.error(error);
        notification.error({ message: "Failed to register company" });
      },
      onSuccess: async () => {
        queryClient.invalidateQueries({ queryKey: ["admin", "companies"] });
        notification.success({
          description: "Company has been registered",
          message: "Success",
        });
      },
    });

  const { mutate: updateCompany, isPending: isUpdateCompanyPending } =
    useMutation({
      mutationFn: (companyData: T_Company) =>
        axiosInstance.put(`/admin/company/${companyId}`, companyData),
      onError: (error) => {
        console.error(error);
        notification.error({ message: "Failed to update company" });
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes("companies"),
        });
        notification.success({
          description: "Company has been updated",
          message: "Success",
        });
      },
    });

  const isMutationPending =
    isRefreshMemberActivationCodePending ||
    isDeactivateAllMembersPending ||
    isUpdateNotificationsPending ||
    isRefreshAdminActivationCodePending ||
    isCheckoutPending ||
    isRegisterCompanyPending ||
    isJoinCompanyPending ||
    isUpdateCompanyPending;
  return {
    isMutationPending,
    checkout,
    deactivateAllMembers,
    joinCompany,
    refreshAdminActivationCode,
    refreshMemberActivationCode,
    registerCompany,
    updateNotifications,
    updateCompany,
  };
};
