import axiosInstance from "@app/axiosInstance";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { loadStripe } from "@stripe/stripe-js";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";
import { useEffect, useState } from "react";

export const useBillingMutations = () => {
  const queryClient = useQueryClient();
  const { selectedCompany } = useCompany();
  const companyId = selectedCompany?.companyId;
  const [stripe, setStripe] = useState<any>();
  const successUrl = `${window.location.origin}/#/company/${companyId}`;

  useEffect(() => {
    (async () => {
      const stripeResponse = await loadStripe(
        process.env.STRIPE_PUBLIC_KEY || ""
      );
      setStripe(stripeResponse);
    })();
  }, []);

  const { mutate: checkout, isPending: isCheckoutPending } = useMutation({
    mutationFn: () =>
      axiosInstance
        .post("/stripe/checkout", {
          cancelUrl: window.location.href,
          companyId,
          successUrl,
        })
        .then((response) => response.data),
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to checkout" });
    },
    onSuccess: async ({ sessionId }: { sessionId: string }) => {
      if (!sessionId || !stripe) {
        throw new Error("Failed to create session");
      }
      const checkoutResponse = await stripe.redirectToCheckout({
        sessionId,
      });
      if (checkoutResponse.error) {
        throw checkoutResponse.error;
      }
      queryClient.invalidateQueries({ queryKey: ["company", companyId] });
      notification.success({
        description: "Checkout successful",
        message: "Success",
      });
    },
  });

  const isMutationPending = isCheckoutPending;
  return { checkout, isMutationPending };
};
