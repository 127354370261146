import { T_DateRangeProps } from "@app/_shared_components/DateFilter";
import axiosInstance from "@app/axiosInstance";
import { normalizeDates, removeEmptyFields } from "@app/modules/utilities";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { useQuery } from "@tanstack/react-query";
import { useCallback } from "react";

export const useBillingQueries = () => {
  const { selectedCompany } = useCompany();
  const companyId = selectedCompany?.companyId;

  const getPayments = () => {
    const { data: payments, isPending: isPaymentsPending } = useQuery({
      queryKey: ["payments", companyId],
      queryFn: () =>
        axiosInstance
          .get(`/admin/company/${companyId}/payment`)
          .then((response) =>
            response.data.map(normalizeDates).map(removeEmptyFields)
          ),
      enabled: !!companyId,
      throwOnError: true,
    });
    return { payments, isPaymentsPending };
  };

  const getReportAlerts = (props: T_DateRangeProps) => {
    const { fromDate, toDate } = props || {};
    const { data: reportAlerts, isPending: isReportAlertsPending } = useQuery({
      queryKey: ["reports", "alert", companyId, fromDate, toDate],
      queryFn: useCallback(
        () =>
          axiosInstance
            .get(
              `/admin/company/${companyId}/report-alerts?fromDate=${fromDate}&toDate=${toDate}`
            )
            .then((response) =>
              response.data.map(normalizeDates).map(removeEmptyFields)
            ),
        [companyId, fromDate, toDate]
      ),
      enabled: !!companyId,
      throwOnError: true,
    });
    return { reportAlerts, isReportAlertsPending };
  };

  return { getPayments, getReportAlerts };
};
