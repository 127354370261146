import { useCompanyMutations } from "@app/entities/company/mutations";
import { InputRef } from "antd/lib/input";
import { useRef, useState } from "react";

export const useJoinCompanyModalLoader = () => {
  const [adminActivationCode, setAdminActivationCode] = useState("");
  const inputRef = useRef<InputRef>(null);
  const { joinCompany, isMutationPending } = useCompanyMutations();

  const isLoading = isMutationPending;
  return {
    joinCompany,
    setAdminActivationCode,
    inputRef,
    adminActivationCode,
    isLoading,
  };
};
