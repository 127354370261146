import axiosInstance from "@app/axiosInstance";
import { normalizeDates, removeEmptyFields } from "@app/modules/utilities";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { useQuery } from "@tanstack/react-query";

export const useAdminQueries = () => {
  const { selectedCompany } = useCompany();
  const companyId = selectedCompany?.companyId;

  const getAdmins = () => {
    const { data: admins, isPending: isAdminsPending } = useQuery({
      queryKey: ["admins", companyId],
      queryFn: () =>
        axiosInstance
          .get(`/admin/${companyId}/admin`)
          .then((response) =>
            response.data.map(normalizeDates).map(removeEmptyFields)
          ),
      throwOnError: true,
      enabled: !!companyId,
    });

    return { admins, isAdminsPending };
  };

  return { getAdmins };
};
