import { useAdminMutations } from "@app/entities/admin/mutations";
import { useAdminQueries } from "@app/entities/admin/queries";
import { useCompanyMutations } from "@app/entities/company/mutations";
import { InputRef } from "antd/lib/input";
import { useRef } from "react";

export const useAdminLoader = () => {
  const activationCodeReference = useRef<InputRef>(null);
  const { getAdmins } = useAdminQueries();
  const { admins, isAdminsPending } = getAdmins();
  const {
    refreshAdminActivationCode,
    isMutationPending: isCompanyMutationPending,
  } = useCompanyMutations();
  const {
    makeAdminOwner,
    removeAdminFromCompany,
    isMutationPending: isAdminMutationPending,
  } = useAdminMutations();

  const isLoading = isAdminsPending || isAdminMutationPending;
  return {
    activationCodeReference,
    admins,
    isLoading,
    isCompanyMutationPending,
    refreshAdminActivationCode,
    makeAdminOwner,
    removeAdminFromCompany,
  };
};
