import { useCompany } from "@app/providers/CompanyContextProvider";
import { useEffect, useState } from "react";
import { useParams } from "react-router";

export const useAdminAppLoader = () => {
  const { companyId } = useParams();
  const [navigationIsCollapsed, setNavigationIsCollapsed] = useState(false);
  const [isJoinCompanyModalOpen, setIsJoinCompanyModalOpen] = useState(false);
  const {
    companies,
    isCompaniesPending,
    selectedCompany,
    setSelectedCompanyId,
  } = useCompany();

  useEffect(() => {
    if (companyId) {
      setSelectedCompanyId(Number(companyId));
    }
  }, [companyId]);

  let color = "";
  let backgroundColor = "";
  let companyStatusText = "";

  switch (selectedCompany?.status) {
    case "a":
      color = "#FFF";
      break;
    case "r":
      backgroundColor = "var(--error-color)";
      color = "#FFF";
      companyStatusText = " (Requires Payment)";
      break;
    case "i":
      backgroundColor = "var(--secondary-light)";
      color = "var(--primary-dark)";
      companyStatusText = " (Inactive)";
      break;
    default:
      break;
  }
  const companyName = selectedCompany?.name || "Add Association";
  const openModal = () => setIsJoinCompanyModalOpen(true);
  const closeModal = () => setIsJoinCompanyModalOpen(false);

  const isLoading = isCompaniesPending;
  return {
    isJoinCompanyModalOpen,
    navigationIsCollapsed,
    backgroundColor,
    color,
    companyName,
    companyStatusText,
    selectedCompany,
    closeModal,
    openModal,
    setNavigationIsCollapsed,
    setSelectedCompanyId,
    companies,
    isLoading,
  };
};
