import axiosInstance from "@app/axiosInstance";
import { T_Report } from "@app/entities/report/types";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import notification from "antd/lib/notification";

import axios from "axios";

export const useReportMutations = () => {
  const { selectedCompany } = useCompany();
  const queryClient = useQueryClient();
  const companyId = selectedCompany?.companyId;

  const { isPending: isBroadcastReportPending, mutate: broadcastReport } =
    useMutation({
      mutationFn: (reportId: number) =>
        axiosInstance.put(`/admin/${companyId}/report/broadcast`, {
          reportId,
        }),
      onSuccess: () => {
        notification.success({
          description: "Report has been broadcast",
          message: "Success",
        });
        queryClient.invalidateQueries({
          predicate: (query) =>
            query.queryKey.includes("reports") ||
            query.queryKey.includes("companies"),
        });
      },
      onError: (error) => {
        console.error(error);
        if (axios.isAxiosError(error)) {
          if (error.response?.status === 444) {
            notification.warning({
              message: "No members found to alert.",
            });
          }
        }
      },
    });

  const { mutate: updateReport, isPending: isUpdateReportPending } =
    useMutation({
      mutationFn: (payload: T_Report) =>
        axiosInstance.put(`/admin/${companyId}/report`, payload),
      onSuccess: () => {
        notification.success({
          description: "Report has been updated",
          message: "Success",
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes("reports"),
        });
      },
    });

  const { mutate: markReportBad, isPending: isMarkReportBadPending } =
    useMutation({
      mutationFn: ({
        reportId,
        reason,
      }: {
        reportId: number;
        reason: string;
      }) =>
        axiosInstance.put(`/admin/${companyId}/report/bad`, {
          reportId,
          reason,
        }),
      onSuccess: () => {
        notification.success({
          description: "Report has been marked as bad",
          message: "Success",
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes("reports"),
        });
      },
      onError: (error) => {
        console.error(error);
        notification.error({ message: "Failed to mark report as bad" });
      },
    });

  const { mutate: deleteReport, isPending: isDeleteReportPending } =
    useMutation({
      mutationFn: (reportId: number) =>
        axiosInstance.delete(`/admin/${companyId}/report`, {
          // Stupid, but this is how Axios handles payloads for DELETE
          data: { reportId },
        }),
      onSuccess: () => {
        notification.success({
          description: "Report has been deleted",
          message: "Success",
        });
        queryClient.invalidateQueries({
          predicate: (query) => query.queryKey.includes("reports"),
        });
      },
      onError: (error) => {
        console.error(error);
        notification.error({ message: "Failed to delete report" });
      },
    });

  const { isPending: isResetReportPending, mutate: resetReport } = useMutation({
    mutationFn: (reportId: number) =>
      axiosInstance.put(`/admin/${companyId}/report/reset`, { reportId }),
    onSuccess: () => {
      notification.success({
        description: "Report has been reset",
        message: "Success",
      });
      queryClient.invalidateQueries({
        predicate: (query) =>
          query.queryKey.includes("reports") ||
          query.queryKey.includes("companies"),
      });
    },
    onError: (error) => {
      console.error(error);
      notification.error({ message: "Failed to reset report" });
    },
  });

  const isMutationPending =
    isBroadcastReportPending ||
    isResetReportPending ||
    isUpdateReportPending ||
    isMarkReportBadPending ||
    isDeleteReportPending;
  return {
    isMutationPending,
    resetReport,
    broadcastReport,
    updateReport,
    markReportBad,
    deleteReport,
  };
};
