import { useCompany } from "@app/providers/CompanyContextProvider";
import { useEffect, useRef, useState } from "react";
import { useSearchParams } from "react-router-dom";

export const useDashboardLoader = () => {
  const widgetReference = useRef<HTMLDivElement>(null);
  const [currentTabKey, setCurrentTabKey] = useState<string>("details");
  const [searchParams] = useSearchParams();
  const { selectedCompany, isCompaniesPending } = useCompany();
  const isOwner = !!selectedCompany?.isOwner;

  useEffect(() => {
    if (searchParams) {
      const tab = searchParams.get("tab");
      if (tab && tab !== currentTabKey) {
        setCurrentTabKey(tab);
      }
    }
  }, [searchParams]);

  const tabList = [
    {
      key: "details",
      tab: "Details",
    },
    {
      key: "notifications",
      tab: "Notifications",
    },
    {
      key: "billing",
      tab: "Billing",
    },
  ];

  if (!!isOwner)
    tabList.push({
      key: "admins",
      tab: "Admins",
    });

  const isLoading = isCompaniesPending;
  return {
    currentTabKey,
    setCurrentTabKey,
    tabList,
    widgetReference,
    isLoading,
    selectedCompany,
  };
};
