import { useCompanyMutations } from "@app/entities/company/mutations";
import { useCompanyQueries } from "@app/entities/company/queries";
import { useCompany } from "@app/providers/CompanyContextProvider";
import { InputRef } from "antd";
import { useRef } from "react";

export const useDetailsLoader = () => {
  const { selectedCompany, isCompaniesPending } = useCompany();
  const isOwner = !!selectedCompany?.isOwner;
  const { getCompanyData } = useCompanyQueries();
  const { fetchCompanyData, isCompanyDataFetching } = getCompanyData();
  const activationCodeReference = useRef<InputRef>(null);
  const {
    refreshMemberActivationCode,
    deactivateAllMembers,
    isMutationPending,
  } = useCompanyMutations();

  const isLoading =
    isCompanyDataFetching || isMutationPending || isCompaniesPending;
  return {
    fetchCompanyData,
    refreshMemberActivationCode,
    deactivateAllMembers,
    activationCodeReference,
    isLoading,
    selectedCompany,
    isOwner,
  };
};
