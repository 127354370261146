import { useCompanyMutations } from "@app/entities/company/mutations";
import Form from "antd/lib/form";

export const useCompanyFormLoader = () => {
  const [companyForm] = Form.useForm();
  const { updateCompany, isMutationPending } = useCompanyMutations();

  const isLoading = isMutationPending;
  return { companyForm, updateCompany, isLoading };
};
