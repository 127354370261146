import { useAdminMutations } from "@app/entities/admin/mutations";
import type { AlertProps } from "antd/lib/alert";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

const isLive = ["production", "staging"].includes(process.env.NODE_ENV ?? "");

export const useLoginLoader = () => {
  const [email, setEmail] = useState("");
  const [recaptchaToken, setRecaptchaToken] = useState("");
  const [refreshReCaptchaToken, setRefreshReCaptchaToken] = useState(false);
  const [alert, setAlert] = useState<AlertProps>({
    message: "",
    type: undefined,
  });
  const { sendMagicLink, isMutationPending } = useAdminMutations();

  const { executeRecaptcha } = useGoogleReCaptcha();

  useEffect(() => {
    setRefreshReCaptchaToken((state) => !state);
    return () => setRefreshReCaptchaToken((state) => !state);
  }, []);

  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      return;
    }
    const token = await executeRecaptcha("login_admin");
    setRecaptchaToken(token);
  }, [executeRecaptcha]);

  const handleLogin = async (strategy: "google" | "magic-login") => {
    setRefreshReCaptchaToken((state) => !state);
    if (strategy === "magic-login") {
      if (!email)
        return setAlert({ message: "Please enter an email", type: "warning" });
      try {
        sendMagicLink({ email, recaptchaToken });
      } catch (error) {
        setAlert({
          message:
            "Something went wrong sending a link. Please refresh the page and try again",
          type: "error",
        });
        console.error(error);
      }
      return;
    }

    const path = `/api/oauth/${strategy}/admin`;
    // It would be nice to return to the authenticated URL after authenticating,
    // but it's not clear how to do that with the current setup.
    // This works, but only if they user is already logged in to their OAuth account
    // const path = `/api/oauth/${strategy}/admin?returnTo=${returnTo ?? ""}`;
    const url = isLive ? path : `http://localhost:8335${path}`;
    window.location.href = url;
  };

  const isLoading = isMutationPending;
  return {
    handleLogin,
    setEmail,
    handleReCaptchaVerify,
    alert,
    email,
    isLoading,
    refreshReCaptchaToken,
  };
};
